<template lang="html">
  <div class="" v-if='camp'>
    <h2 class='camp-name'>Smartlink "{{ camp.name }}"</h2>
    <CampaignEdit />
    <br>
    <hr>
    <h4>Ad Set</h4>
    <Table :draggable='true' :onOrderUpdate='saveOrder'
      :fields='{ id: "ID", geo: "Geo", region: "Region", os: "OS", frq: "Frq", ip_frq: "IP Frq", comments: "Comments" }'
      :data='placements' v-if='placements'
      :links='{ Edit: v => ({ name: "SmartLinksPlacementEdit", params: { placementId: v.id } }) }'
      :onRowClick='onRowClick' />

    <button v-if='userHasPermission("smartlinks.edit")' type="button"
      @click='newPlacementFormDisplay = !newPlacementFormDisplay'
      class="btn btn-outline-primary smartlinks-toggle-form-display-button">New Ad Set</button>

    <NewPlacement v-if='newPlacementFormDisplay' :camp_id='camp.id' />


    <div class="link-23">
      <span class="c-12345">
        Campaign Link:
      </span>
      <input @focus='onSystemLinkFocus' class="c-231" :value='systemCampLink' readonly>
      </input>
    </div>
    <hr>
    </hr>

    <div v-if="subsValidationError.length > 0" class="alert">
      <a class="close" @click="subsValidationError = []">&times;</a>
      <li v-for="msg in subsValidationError">{{ msg }}</li>
    </div>

    <div class="subs-bl">
      <h4>Subs Blacklisting</h4>
      <div class="blacklist-container" v-show='userHasPermission("smartlinks.edit")'>
        <div class="inline-container">
          <label>Sub:</label>
          <Select class="select" :options="unselectedSubs" v-model="new_sub_bl.sub" @change="changeSelectedSub" />
          <input id="new-sub-bl-wl" v-model='new_sub_bl.whitelist' type="checkbox">
          <label for="new-sub-bl-wl">Whitelist</label>
          <i class="fa fa-plus edit-btn" aria-hidden="true" @click='onSubsBlacklistAdd'></i>

        </div>
        <div>
          <span v-if="selectedSub === 'sub2' || selectedSub === 'sub2' || selectedSub === 'sub2'"
            style="color: red;">Case sensitive</span>
          <span v-if="selectedSub === 'sub4'" style="color: red;">Not case sensitive</span>
        </div>
        <div class="relative">
          <p class="absolute right top translate-y-100">
            <span>{{ new_sub_bl?.list?.length ? addCommaToNumber(new_sub_bl?.list?.length) : 0 }}</span> / <span>65,000</span>
          </p>
          <LimitedTextarea class="max-textarea" rows="4" :max="3000000" v-model="new_sub_bl.list"
            @input="onFirstTextareaInput"></LimitedTextarea>
        </div>
      </div>
      <br class="flat-breaks"><br>
      <div>
        <div v-for="(s, i) in form.blacklisting_subs">
          <div class="inline-container">
            <i class="fa fa-trash trash-btn" aria-hidden="true" @click='onSubsBlacklistRemove(i)'></i>
            <h5>{{ s.sub }}</h5>
            <i class="fa fa-plus edit-btn" aria-hidden="true" @click='addAnotherLimitedTextArea(s, i)'></i>
          </div>
          <div>
            <input :disabled='!userHasPermission("smartlinks.edit")' v-model='s.whitelist' type="checkbox">
            <label>Whitelist</label>
          </div>
          <div>
            <span v-if="s.sub === 'sub2' || s.sub === 'sub3' || s.sub === 'sub7'" style="color: red;">Case
              sensitive</span>
            <span v-if="s.sub === 'sub4'" style="color: red;">Not case sensitive</span>
          </div>
          <div class="relative">
            <p class="absolute right top translate-y-100">
              <span>{{ s?.list?.length ? addCommaToNumber(s?.list?.length) : 0 }}</span> / <span>65,000</span>
            </p>
            <LimitedTextarea class="max-textarea" rows="4" :max="3000000" v-model="s.list"></LimitedTextarea>
          </div>
          <br class="flat-breaks"><br>
          <div v-for="(extra_sub_list, extra_sub_list_idx) in s.extra_sub_list">
            <i class="fa fa-trash trash-btn mb-3" aria-hidden="true" @click='removeExtraSubBlWl(s, extra_sub_list)'></i>
            <div class="relative">
              <p class="absolute right top translate-y-100">
                <span>{{ extra_sub_list.value?.length ? addCommaToNumber(extra_sub_list.value.length) : 0 }}</span> / <span>65,000</span>
              </p>
              <LimitedTextarea class="max-textarea" rows="4" :max="3000000"
                v-model="s.extra_sub_list[extra_sub_list_idx].value">
              </LimitedTextarea>
            </div>
            <br class="flat-breaks"><br>
          </div>
        </div>
      </div>
      <b-button variant="success" @click='submitSubsBlacklisting'>save</b-button>
    </div>

  </div>
</template>

<script>
import NewPlacement from '../NewPlacement.smartlinks.vue'
import draggable from 'vuedraggable'
import CampaignEdit from '../in-view/EditCampaign.smartlinks'

export default {
  components: {
    NewPlacement,
    draggable,
    CampaignEdit
  },
  data() {
    return {
      form: {
        blacklisting_subs: [],
      },
      extra_sub_blwl_to_delete: [],
      firstLimitedTextarea: '',
      subsValidationError: [],
      camp: null,
      placements: null,
      newPlacementFormDisplay: false,
      orderEdit: false,
      originalOrder: null,
      systemLinkTemplate: process.env.VUE_APP_SMARTLINKS_SYSTEM_LINK,
      new_sub_bl: {},
      selectedSub: '',
      all_subs: [{
        val: "sub1",
        label: "Sub1"
      },
      {
        val: "sub2",
        label: "Sub2"
      },
      {
        val: "sub3",
        label: "Sub3"
      },
      {
        val: "sub4",
        label: "Sub4"
      },
      {
        val: "sub6",
        label: "Sub6"
      },
      {
        val: "sub7",
        label: "Sub7"
      },
      {
        val: "sub8",
        label: "Sub8"
      }
      ],
    }
  },
  computed: {
    systemCampLink() {
      return this.systemLinkTemplate.replace("{camp_id}", this.$route.params.campId)
    },
    unselectedSubs() {
      if (!this.all_subs || !this.all_subs.length) {
        return
      }
      return this.all_subs.filter(v => !this.form.blacklisting_subs.find(v2 => v2.sub == v.val))
    },
  },
  methods: {
    addAnotherLimitedTextArea(s, idx) {
      let campId = this.$route.params.campId
      const current_form = JSON.parse(JSON.stringify(this.form));
      current_form.blacklisting_subs[idx] = {
        ...s,
        extra_sub_list: [...s.extra_sub_list, { value: '', sub_idx: s.extra_sub_list.length, sub: s.sub, sl_id: campId }]
      }
      this.form = current_form;
    },
    removeExtraSubBlWl(sub, extra_bl_wl_to_delete) {
      console.log(this.extra_sub_blwl_to_delete);
      const form_copy = JSON.parse(JSON.stringify(this.form));
      const extra_sub_blwl_to_delete_copy = JSON.parse(JSON.stringify(this.extra_sub_blwl_to_delete));
      for (let k in form_copy.blacklisting_subs) {
        if (form_copy.blacklisting_subs[k].sub == sub.sub) {
          if (extra_bl_wl_to_delete.id) {
            extra_sub_blwl_to_delete_copy.push(extra_bl_wl_to_delete)
          }
          form_copy.blacklisting_subs[k].extra_sub_list = form_copy.blacklisting_subs[k].extra_sub_list.filter(v => v.sub_idx != extra_bl_wl_to_delete.sub_idx)
          form_copy.blacklisting_subs[k].extra_sub_list = form_copy.blacklisting_subs[k].extra_sub_list.map((v, i) => {
            v.sub_idx = i
            return v
          })
          console.log('form_copy.blacklisting_subs[k].extra_sub_list', form_copy.blacklisting_subs[k].extra_sub_list);

        }
      }
      this.form = form_copy;
      this.extra_sub_blwl_to_delete = extra_sub_blwl_to_delete_copy;
    },
    changeSelectedSub() {
      if (!this.new_sub_bl || !this.new_sub_bl.sub) {
        return
      } else {
        this.selectedSub = this.new_sub_bl.sub.val
      }
    },
    onSubsBlacklistAdd() {
      if (!this.new_sub_bl || !this.new_sub_bl.sub) {
        return
      }
      this.selectedSub = ''
      let newsub = this.new_sub_bl
      this.new_sub_bl = {}
      newsub.sub = newsub.sub.val
      newsub.extra_sub_list = []
      this.form.blacklisting_subs.unshift(newsub)
    },
    onSubsBlacklistRemove(i) {
      this.form.blacklisting_subs.splice(i, 1);
    },
    onSystemLinkFocus(v) {
      v.target.select && v.target.select()
    },
    onRowClick(v) {
      const route = this.$route.fullPath
      this.$router.push({
        name: route.includes("VTA") ? "SmartLinksVTAPlacementId" : "SmartLinksPlacementId",
        params: {
          placementId: v.id
        }
      })
    },
    saveOrder: async function (order) {
      let newOrder = order.map(v => v.id).join(',')
      this.placements.length = 0
      this.placements.push.apply(this.placements, order)
      let campId = this.$route.params.campId
      try {
        await this.$http.put(this.resources.SmartLinksCampaigns.smartLinksUpdateCampaignOrder(campId), {
          order: newOrder
        })
        this.camp.placements_order = newOrder

      } catch (err) {
        this.notifyError("Failed to change placements order")
      }
    },
    async validateBlacklistingSubs() {
      let subsMsgArray = []
      let matchedArray = []
      for (let k in this.form.blacklisting_subs) {
        matchedArray = this.form.blacklisting_subs[k].list.match(/[\r\n\t\f\v\s]/g) || []
        let lastMatch = 0
        let msgArray = []

        for (const i in matchedArray) {
          lastMatch = this.form.blacklisting_subs[k].list.indexOf(matchedArray[i], lastMatch)
          msgArray.push({
            str: this.form.blacklisting_subs[k].list.substring(lastMatch - 10, lastMatch + 10),
            char: matchedArray[i]
          })
          lastMatch++
        }
        if (matchedArray.length > 0) {
          subsMsgArray[this.form.blacklisting_subs[k].sub] = msgArray
        }
      }
      return subsMsgArray
    },
    ThrowSubsAlerst(subsMsgArray) {
      let msg = []
      for (const k in subsMsgArray) {
        for (const i in subsMsgArray[k]) {
          let txt = ''
          switch (subsMsgArray[k][i].char) {
            case ' ':
              txt = `There is a space in ${k} between - ${subsMsgArray[k][i].str}`
              break
            case '\n':
              txt = `There is a line break in ${k} between - '${subsMsgArray[k][i].str}''`
              break
            case '\t':
              txt = `There is a tab in ${k} between - ${subsMsgArray[k][i].str}`
              break

          }
          msg.push(txt)
        }
      }
      this.subsValidationError = msg
    },
    submitSubsBlacklisting: async function () {
      console.log('started');
      for (let k in this.form.blacklisting_subs) {
        if (this.form.blacklisting_subs[k].list.length > 65000) {
          this.notifyError(`Sub BL/WL Can't be more than 65000 characters`)
          return
        }
        for (let i in this.form.blacklisting_subs[k].extra_sub_list) {
          if (this.form.blacklisting_subs[k].extra_sub_list[i].value.length > 65000) {
            this.notifyError(`Extra BL/WL Can't be more than 65000 characters`)
            return
          }
        }
      }
      const form_copy = JSON.parse(JSON.stringify(this.form))
      let extra_sub_list = [];
      for (let k in form_copy.blacklisting_subs) {
        for (let i in form_copy.blacklisting_subs[k].extra_sub_list) {
          extra_sub_list.push(form_copy.blacklisting_subs[k].extra_sub_list[i])
        }
        delete form_copy.blacklisting_subs[k].extra_sub_list
      }

      this.subsValidationError = []
      const subsMsgArray = await this.validateBlacklistingSubs()
      if (Object.keys(subsMsgArray).length > 0) {
        this.ThrowSubsAlerst(subsMsgArray)
        return
      }
      let campId = this.$route.params.campId

      try {
        await this.$http.post(this.resources.SmartLinksCampaigns.postCampaignBl(campId), form_copy);

        await this.$http.post(this.resources.SmartLinksCampaigns.postCampaignExtraSubList(campId), extra_sub_list);
        // if (this.extra_sub_blwl_to_delete.length > 0) {
        //   await this.$http.post(this.resources.SmartLinksCampaigns.deleteCampaignExtraSubList(campId), this.extra_sub_blwl_to_delete);
        // }
        this.notifySuccess(`Campaign ${this.camp.name} successfully updated blacklisting data and extra sub list`)
      }
      catch (err) {
        this.notifyError(`Campaign ${this.camp.name} didn't update blacklisting data or extra sub list or both`)
      }
    },
    getCampSubsBl: async function () {
      let campId = this.$route.params.campId
      try {
        const r = await this.$http.get(this.resources.SmartLinksCampaigns.smartLinksCampaignGetBL(campId));
        const extra_sub_blwl_res = await this.$http.get(this.resources.SmartLinksCampaigns.getCampaignExtraSubList(campId));
        const extra_sub_blwl_data = await extra_sub_blwl_res.json();
        const data = await r.json()
        data.forEach(element => {
          element.extra_sub_list = extra_sub_blwl_data.filter(v => v.sub == element.sub).sort((a, b) => a.sub_idx - b.sub_idx)
        });
        this.form.blacklisting_subs = data
      } catch (err) {
        this.notifyError("Failed to load camps blacklisting")
      }
    },
    addCommaToNumber: function (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  created() {
    this.getCampSubsBl()
    let campId = this.$route.params.campId
    let camp = this.$store.state.smartlinks.campaigns.find(v => v.id == campId)
    this.camp = camp
    this.placements = camp.placements
  }
}
</script>

<style lang="css" scoped>
.subs-bl {
  margin-top: 5%;
}

.camp-name {
  margin-bottom: 20px;
}

.link-23 {
  margin-top: 17px;
}

.c-231 {
  padding: 7px;
  background: #f9f9f9;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  width: 750px;

}

.c-12345 {
  margin-right: 5px;
}

.max-textarea {
  width: 100%;
}


.max-textarea {
  width: 100%;
}

.inline-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.inline-container>* {
  margin-right: 9px;
}

.flat-breaks br {
  content: "";
}

.flat-breaks br:after {
  content: " ";
}

.alert {
  padding: 20px;
  margin-bottom: 15px;
  position: fixed;
  top: 5%;
  left: 25%;
  right: 0;
  z-index: 999999;
  width: 50%;
  box-shadow: 0 0 15px 5px #ccc;
  border-radius: 5px;
  background-color: lightcoral;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.right {
  right: 0;
}

.top {
  top: 0;
}

.translate-y-100 {
  transform: translateY(-100%);
}

</style>